var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"app--drawer",attrs:{"app":"","mini-variant":_vm.mini,"width":_vm.drawerWidth,"dark":_vm.$vuetify.dark},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"grey lighten-3"},[(_vm.drawerWidth === 64)?[_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mx-auto",attrs:{"width":"64","icon":"","tile":""},on:{"click":_vm.handleDrawerCollapse}},[_c('v-icon',[_vm._v("mdi-arrow-collapse-right")])],1)],1)]:[_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","tile":""},on:{"click":_vm.handleDrawerCollapse}},[_c('v-icon',[_vm._v("mdi-arrow-collapse-left")])],1)],1)]],2)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-toolbar',{attrs:{"color":"primary darken-1","dark":""}},[_c('img',{staticClass:"rounded",attrs:{"src":require("@/assets/logo_bg_white_128.png"),"height":"36","alt":"CDMS"}}),_c('v-toolbar-title',{staticClass:"ml-0 pl-3"},[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.title')))])])],1),(_vm.user)?_c('v-list',{staticClass:"pa-0"},[_vm._l((_vm.computeMenu),function(item,key){return [(!item.meta.hiddenInMenu && item.children && item.children.length > 1)?[_c('v-list-group',{key:key,attrs:{"no-action":"","to":item.path},scopedSlots:_vm._u([{key:"prependIcon",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({domProps:{"textContent":_vm._s(item.meta.icon)}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.route.' + item.name))+" ")])])]},proxy:true},{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$vuetify.lang.t('$vuetify.route.' + item.name))}})],1)]},proxy:true}],null,true)},_vm._l((item.children),function(subItem){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(!subItem.meta.hiddenInMenu),expression:"!subItem.meta.hiddenInMenu"}],key:subItem.name,class:_vm.drawerWidth === 64 ? 'pl-4' : '',attrs:{"to":item.path+'/'+subItem.path}},[(_vm.drawerWidth === 64)?[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({domProps:{"textContent":_vm._s(subItem.meta.icon)}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.route.' + subItem.name)))])])],1)]:[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
                    _vm.$vuetify.lang.t('$vuetify.route.' + subItem.name)
                  )}})],1)]],2)}),1)]:[_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(!item.meta.hiddenInMenu),expression:"!item.meta.hiddenInMenu"}],key:key,attrs:{"to":item.path}},[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({domProps:{"textContent":_vm._s(item.meta.icon)}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.route.' + item.name)))])])],1),(_vm.drawerWidth !== 64)?_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$vuetify.lang.t('$vuetify.route.' + item.name))}})],1):_vm._e(),(item.meta.new)?_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-new-box ")])],1):_vm._e()],1)]]})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-navigation-drawer
    app
    class="app--drawer"
    :mini-variant.sync="mini"
    v-model="drawer"
    :width="drawerWidth"
    :dark="$vuetify.dark"
  >
    <v-toolbar color="primary darken-1" dark>
      <img
        class="rounded"
        src="@/assets/logo_bg_white_128.png"
        height="36"
        alt="CDMS"
      />
      <v-toolbar-title class="ml-0 pl-3">
        <span class="hidden-sm-and-down">{{ $vuetify.lang.t('$vuetify.title') }}</span>
      </v-toolbar-title>
    </v-toolbar>
    <v-list
      v-if="user"
      class="pa-0"
    >
      <template v-for="(item, key) in computeMenu">
        <template v-if="!item.meta.hiddenInMenu && item.children && item.children.length > 1">
          <v-list-group :key="key" no-action :to="item.path">
            <template v-slot:prependIcon>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" v-text="item.meta.icon" />
                </template>
                <span>
                  {{ $vuetify.lang.t('$vuetify.route.' + item.name) }}
                </span>
              </v-tooltip>
            </template>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title
                  v-text="$vuetify.lang.t('$vuetify.route.' + item.name)"
                />
              </v-list-item-content>
            </template>
            <v-list-item
              :class="drawerWidth === 64 ? 'pl-4' : ''"
              v-for="subItem in item.children"
              :key="subItem.name"
              :to="item.path+'/'+subItem.path"
              v-show="!subItem.meta.hiddenInMenu"
            >
              <template v-if="drawerWidth === 64">
                <v-list-item-icon>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        v-text="subItem.meta.icon"
                      />
                    </template>
                    <span>{{
                      $vuetify.lang.t('$vuetify.route.' + subItem.name)
                    }}</span>
                  </v-tooltip>
                </v-list-item-icon>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="
                      $vuetify.lang.t('$vuetify.route.' + subItem.name)
                    "
                  />
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-group>
        </template>
        <template v-else>
          <v-list-item
            :key="key"
            :to="item.path"
            v-show="!item.meta.hiddenInMenu"
          >
            <v-list-item-icon>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" v-text="item.meta.icon" />
                </template>
                <span>{{
                  $vuetify.lang.t('$vuetify.route.' + item.name)
                }}</span>
              </v-tooltip>
            </v-list-item-icon>
            <v-list-item-content v-if="drawerWidth !== 64">
              <v-list-item-title
                v-text="$vuetify.lang.t('$vuetify.route.' + item.name)"
              />
            </v-list-item-content>
            <v-list-item-action v-if="item.meta.new">
              <v-icon color="green">mdi-new-box </v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
      </template>
    </v-list>
    <template v-slot:append>
      <div class="grey lighten-3">
        <template v-if="drawerWidth === 64">
          <div class="d-flex">
            <v-btn
              width="64"
              icon
              tile
              @click="handleDrawerCollapse"
              class="mx-auto"
            >
              <v-icon>mdi-arrow-collapse-right</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-else>
          <div class="d-flex">
            <v-spacer />
            <v-btn icon tile @click="handleDrawerCollapse" class="mr-2">
              <v-icon>mdi-arrow-collapse-left</v-icon>
            </v-btn>
          </div>
        </template>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
  import { mapGetters } from 'vuex';

  import { default as routes } from '@/router/routes';

  export default {
    name: 'AppNavigationDrawer',
    components: {},
    props: {
      expanded: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        mini: false,
        drawerWidth: 256,
        drawer: true,
        scrollSettings: {
          maxScrollbarLength: 160
        },
        items: [
          {
            icon: 'mdi-view-dashboard',
            title: 'dashboard',
            to: '/'
          },
          {
            icon: 'mdi-account',
            title: 'user',
            to: '/pages/user'
          },
          {
            title: 'rtables',
            icon: 'mdi-clipboard-outline',
            to: '/tables/regular-tables'
          },
          {
            title: 'typography',
            icon: 'mdi-format-font',
            to: '/components/typography'
          },
          {
            title: 'icons',
            icon: 'mdi-chart-bubble',
            to: '/components/icons'
          },
          {
            title: 'google',
            icon: 'mdi-map-marker',
            to: '/maps/google-maps'
          },
          {
            title: 'notifications',
            icon: 'mdi-bell',
            to: '/components/notifications'
          }
        ]
      };
    },

    computed: {
      ...mapGetters({
        user: 'auth/user'
      }),

      computeMenu() {
        return this.routesTomenus(routes);
      }
    },

    created() {},

    methods: {
      handleDrawerCollapse() {
        this.drawerWidth = this.drawerWidth === 256 ? 64 : 256;
      },
      toggleDrawer() {
        this.drawer = !this.drawer;
      },
      checkPermission(meta) {
        if (meta.roles && meta.roles instanceof Array && meta.roles.length > 0) {
          const roles = this.user && this.user.roles
          const permissionRoles = meta.roles

          const hasPermission = roles.some(role => {
            return permissionRoles.includes(role)
          })

          if (!hasPermission) {
            return false
          }


          if (meta.activeLevel) {
            if (!this.user.profile || !this.user.profile.regionLevel || this.user.profile.regionLevel !== meta.activeLevel) {
              return false
            }
          }

          return true
        } else {
          return false
        }
      },

      routesTomenus(menus = []) {
        let res = [], menu = null
        for (let i = 0; i < menus.length; i++) {
          menu = {}
          menu.path = menus[i].path
          menu.name = menus[i].name
          if (menu.redirect) {
            menu.redirect = menus[i].redirect
          }
          if (menus[i].meta) {
            menu.meta = JSON.parse(JSON.stringify(menus[i].meta))
          }

          if (menus[i].children) {
            menu.children = this.routesTomenus(menus[i].children)
          }
          if (!menu.meta.roles || this.checkPermission(menu.meta)) {
            res.push(menu)
          }
        }
        return res
      }
    }
  };
</script>
